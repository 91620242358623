import React, { FC, useMemo } from 'react';

import SearchResultItem from 'components/SearchResults/SearchResultItem';
import SearchBar from 'components/common/SearchBar';
import { fillWithVariables } from 'utils/stringFormat';

import './styles.scss';
import { SearchResultProps } from './models';

export const SearchResults: FC<SearchResultProps> = ({
  searchResults,
  buttonText,
  phrase,
  notFoundText,
  foundText,
  totalItems,
}) => {
  const resultText = useMemo(() => fillWithVariables(foundText, [totalItems.toString(), phrase]), [
    foundText,
    totalItems,
    phrase,
  ]);

  return (
    <div className="search-results">
      <h3 className="search-results__summary">{resultText}</h3>
      <SearchBar className="search-results__search-bar" />
      {searchResults.length > 0 ? (
        <ul className="search-results__wrapper">
          {searchResults.map((item) => (
            <SearchResultItem key={item.objectID} {...{ ...item, buttonText }} />
          ))}
        </ul>
      ) : (
        <p>{notFoundText}</p>
      )}
    </div>
  );
};

export default SearchResults;
